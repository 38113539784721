<template>
    <div v-if="terms" class="container pt-6">
        <vue-markdown-plus class="content">{{ terms.body }}</vue-markdown-plus>
    </div>
</template>

<script>
import VueMarkdownPlus from 'vue-markdown-plus'

export default {
    name: 'Terms',
    components: {
        'vue-markdown-plus': VueMarkdownPlus
    },
    data: () => {
        return {
            pageTitle: 'Terms of Use'
        }
    },
    metaInfo() {
        return {
            // title will be appended into the titleTemplate in App.vue
            title: this.pageTitle,
            titleTemplate: 'Eduworks | %s'
        }
    },
    mounted() {
        if (this.$store.state.termsAndConditions === null)
            this.$store.dispatch('getTermsAndConditions')
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl
        },
        terms() {
            return this.$store.state.termsAndConditions
        }
    },
    methods: {}
}
</script>
